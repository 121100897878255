import { useEditableEditor } from "@/hooks/use-editable-editor";
import {
  BugAntIcon,
  ChevronLeftIcon,
  PencilSquareIcon,
} from "@heroicons/react/16/solid";
import { Head, router } from "@inertiajs/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import Editor from "../../components/editor";
import { Subheading } from "../../components/heading";
import { Link } from "../../components/link";
import { useAutoSave } from "../../hooks/use-auto-save";
import { getEditorNodes } from "../../lib/utils";
import { IMAGE_RESIZE_KEY } from "../../prose-mirror/plugins/event-dispatcher";
import { useSidebarStore } from "../../stores/use-sidebar-store";
import ChangeSubjectDialog from "./change-subject-dialog";

export default function Edit({ campaign, is_dev }) {
  const { subject, setSubject, content, setContent } = useAutoSave({
    saveEndpoint: `/campaigns/${campaign.id}`,
    saveInterval: 3000,
    initialSubject: campaign.subject,
    initialContent: campaign.json_content,
    initialSenderId: campaign.sender_id,
    initialPreviewText: campaign.preview_text,
  });
  const { isSidebarOpen } = useSidebarStore();
  const [resizingImageDimensions, setResizingImageDimensions] = useState(null);
  const [isChangeSubjectDialogOpen, setIsChangeSubjectDialogOpen] =
    useState(false);
  const editor = useEditableEditor({
    content,
    id: campaign.id,
    onTransaction: ({ transaction }) => {
      setResizingImageDimensions(transaction.getMeta(IMAGE_RESIZE_KEY));
    },
    onUpdate: ({ editor }) => {
      setContent(JSON.stringify(editor?.getJSON()));
    },
  });

  function handleReviewClick() {
    let allFallbackSet = true;

    getEditorNodes(editor, "dynamicField").forEach(({ node }) => {
      if (!node.attrs.fallback) {
        allFallbackSet = false;
      }
    });

    if (!allFallbackSet) {
      toast.error("Please provide fallback text for all dynamic fields.");
      return;
    }

    router.visit(`/campaigns/${campaign.id}/review`);
  }

  return (
    <>
      <Head title={subject} />

      <div className="flex justify-between max-lg:items-end items-center">
        <div className="max-lg:hidden">
          <Link
            href="/campaigns"
            className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400"
          >
            <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
            Campaigns
          </Link>
        </div>
        <div className="flex items-center gap-x-2">
          <Subheading level={1}>{subject}</Subheading>
          <Badge color="zinc">Draft</Badge>
          <Button plain onClick={() => setIsChangeSubjectDialogOpen(true)}>
            <PencilSquareIcon />
          </Button>
        </div>
        <div className="flex gap-4">
          {is_dev && (
            <Button
              outline
              onClick={() =>
                console.log(JSON.stringify(editor?.getJSON(), null, "\t"))
              }
            >
              <BugAntIcon className="text-zinc-500" />
              Debug JSON
            </Button>
          )}
          {!editor.isEmpty ? (
            <Button color="violet" onClick={handleReviewClick}>
              Review
            </Button>
          ) : (
            <Button color="violet" disabled>
              Review
            </Button>
          )}
        </div>
      </div>

      <div className="px-4 py-20">
        <Editor editor={editor} />
      </div>

      <ChangeSubjectDialog
        isOpen={isChangeSubjectDialogOpen}
        setIsOpen={setIsChangeSubjectDialogOpen}
        subject={subject}
        onSubmit={setSubject}
      />

      {resizingImageDimensions && (
        <Badge
          color="cyan"
          className="fixed bottom-6 left-6 lg:bottom-10 lg:left-10 ordinal slashed-zero tabular-nums"
        >
          {resizingImageDimensions.width}x{resizingImageDimensions.height}
        </Badge>
      )}
    </>
  );
}
