import { formatDistanceToNow } from "date-fns";
import { Badge } from "../../components/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";

export default function List({ automations }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Last update</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {automations.map((automation) => (
          <TableRow key={automation.id} href={`/automations/${automation.id}`}>
            <TableCell className="font-medium">{automation.name}</TableCell>
            <TableCell>
              {automation.active ? (
                <Badge color="lime">Active</Badge>
              ) : (
                <Badge color="zinc">Inactive</Badge>
              )}
            </TableCell>
            <TableCell className="text-zinc-500">
              {formatDistanceToNow(automation.updated_at, { addSuffix: true })}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
