import { Button } from "@/components/button";
import Editor from "@/components/editor";
import { Subheading } from "@/components/heading";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useEditableEditor } from "@/hooks/use-editable-editor";
import { useAutomationStore } from "@/stores/use-automation-store";

export default function EditEmailDrawer() {
  const { subject, previewText, jsonContent, setJsonContent } =
    useAutomationStore();
  const editor = useEditableEditor({
    content: jsonContent,
    onUpdate: ({ editor }) => {
      if (editor) {
        setJsonContent(JSON.stringify(editor.getJSON()));
      }
    },
  });

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button color="violet">Edit email</Button>
      </DrawerTrigger>

      <DrawerContent className="h-[90vh]">
        <div className="p-6">
          {/* Required by screen readers */}
          <DrawerTitle className="hidden">{subject}</DrawerTitle>
          <DrawerDescription className="hidden">
            {previewText}
          </DrawerDescription>

          <Subheading className="text-center">{subject}</Subheading>

          <div className="px-4 py-16">
            <Editor editor={editor} />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
