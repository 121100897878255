import toast from "react-hot-toast";
import { Divider } from "../../components/divider";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../components/fieldset";
import { Heading } from "../../components/heading";
import { Input } from "../../components/input";
import { Switch, SwitchField } from "../../components/switch";
import { useAutomationStore } from "../../stores/use-automation-store";

export default function AutomationFields() {
  const { initialNodes, name, setName, active, setActive } =
    useAutomationStore();

  const handleActiveChange = (isActive) => {
    if (
      isActive &&
      initialNodes.some(
        (node) =>
          node.node_type === "email" &&
          (!node.settings.subject || !node.settings.json_content)
      )
    ) {
      toast.error("Please fill in subject and email content for email nodes.");
      return;
    }

    setActive(isActive);
  };

  return (
    <>
      <div className="mt-4 lg:mt-8">
        <Heading>{name || "Untitled"}</Heading>
        <Divider className="mt-6" />
      </div>

      <div className="mt-10">
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Name</Label>
              <Input
                name="name"
                placeholder="Welcome email"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Field>

            <SwitchField>
              <Label>Active</Label>
              <Description>
                Toggle to activate or deactivate this automation.
              </Description>
              <Switch
                name="active"
                color="lime"
                checked={active}
                onChange={handleActiveChange}
              />
            </SwitchField>
          </FieldGroup>
        </Fieldset>
      </div>
    </>
  );
}
