import { showSuccessToastWithConfetti } from "@/lib/utils";
import { usePage } from "@inertiajs/react";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

export default function Application({ children }) {
  const { flash } = usePage().props;

  useEffect(() => {
    if (flash.notice) {
      toast.success(flash.notice, { duration: 3000 });
    } else if (flash.alert) {
      toast.error(flash.alert);
    } else if (flash.confetti) {
      showSuccessToastWithConfetti(flash.confetti);
    }
  }, [flash]);

  return (
    <>
      <Toaster toastOptions={{ className: "text-sm font-medium !max-w-md" }} />
      {children}
    </>
  );
}
