import * as Headless from "@headlessui/react";
import { ClockIcon, PaperAirplaneIcon } from "@heroicons/react/16/solid";
import {
  Dropdown,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../../components/dropdown";

export default function AddNodeDropdown({ onEdge = false, onAddNode }) {
  return (
    <Dropdown>
      <Headless.MenuButton
        className="w-6 h-6 flex items-center text-zinc-400 hover:text-zinc-600 justify-center cursor-pointer text-sm font-medium rounded-full bg-white dark:bg-zinc-900 ring-1 ring-zinc-950/5 dark:ring-white/10 hover:ring-zinc-950/15 dark:hover:ring-white/20 shadow-sm transition-all duration-200 ease-in-out"
        aria-label="Add node"
      >
        +
      </Headless.MenuButton>

      <DropdownMenu
        anchor={onEdge ? "right" : "bottom"}
        className="min-w-[--button-width] !transition-none"
      >
        <DropdownItem onClick={() => onAddNode("email")}>
          <PaperAirplaneIcon />
          <DropdownLabel>Email</DropdownLabel>
        </DropdownItem>

        <DropdownItem onClick={() => onAddNode("wait")}>
          <ClockIcon />
          <DropdownLabel>Wait</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
