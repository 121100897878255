import { Head } from "@inertiajs/react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Empty from "./empty";
import List from "./list";

export default function Index({ automations }) {
  return (
    <>
      <Head title="Automations" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Automations</Heading>
        {automations.length > 0 ? (
          <div className="flex gap-4">
            <Button color="violet" href="/automations/new">
              Create automation
            </Button>
          </div>
        ) : (
          <Button className="invisible">Create automation</Button>
        )}
      </div>

      {automations.length > 0 ? <List automations={automations} /> : <Empty />}
    </>
  );
}
