import { Head } from "@inertiajs/react";
import Greeting from "../../components/greeting";
import { Subheading } from "../../components/heading";
import { Select } from "../../components/select";
import Stat from "../../components/stat";
import { clickRateColor, openRateColor } from "../../lib/utils";
import CampaignsChart from "./campaigns-chart";
import ContactsChart from "./contacts-chart";

export default function Index({
  first_name,
  contact_metrics,
  contact_chart_data,
  email_metrics,
  email_chart_data,
}) {
  return (
    <>
      <Head title="Dashboard" />

      <Greeting firstName={first_name} />

      <div className="mt-8 flex items-end justify-between">
        <Subheading>Campaigns</Subheading>
        <div>
          <Select name="period" className="invisible">
            <option value="last_week">Last week</option>
            <option value="last_two">Last two weeks</option>
            <option value="last_month">Last month</option>
            <option value="last_quarter">Last quarter</option>
          </Select>
        </div>
      </div>

      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <Stat
          title="Emails sent last 30 days"
          value={email_metrics.emails_sent_last_30_days}
        />
        <Stat
          title="Opened last 30 days"
          value={email_metrics.opened_last_30_days}
          percentage={`${email_metrics.opened_rate_last_30_days}%`}
          percentageColor={openRateColor(
            email_metrics.opened_rate_last_30_days
          )}
        />
        <Stat
          title="Clicked last 30 days"
          value={email_metrics.clicked_last_30_days}
          percentage={`${email_metrics.clicked_rate_last_30_days}%`}
          percentageColor={clickRateColor(
            email_metrics.clicked_rate_last_30_days
          )}
        />
        <Stat
          title="Click-to-open rate last 30 days"
          value={`${email_metrics.ctor}%`}
        />
      </div>

      <div className="mt-8">
        <CampaignsChart data={email_chart_data} />
      </div>

      <div className="mt-8 flex items-end justify-between">
        <Subheading>Contacts</Subheading>
        <div>
          <Select name="period" className="invisible">
            <option value="last_week">Last week</option>
            <option value="last_two">Last two weeks</option>
            <option value="last_month">Last month</option>
            <option value="last_quarter">Last quarter</option>
          </Select>
        </div>
      </div>

      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <Stat
          title="Subscribed today"
          value={contact_metrics.subscribed_today}
        />
        <Stat
          title="Subscribed last 30 days"
          value={contact_metrics.subscribed_last_30_days}
        />
        <Stat
          title="Unsubscribed last 30 days"
          value={contact_metrics.unsubscribed_last_30_days}
        />
        <Stat
          title="Total subscribers"
          value={contact_metrics.total_subscribers}
        />
      </div>

      <div className="mt-8">
        <ContactsChart data={contact_chart_data} />
      </div>
    </>
  );
}
