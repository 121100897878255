import { ClockIcon } from "@heroicons/react/24/solid";
import { Badge } from "../../components/badge";
import { Divider } from "../../components/divider";
import { Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Heading } from "../../components/heading";
import { Input } from "../../components/input";
import { Listbox, ListboxOption } from "../../components/listbox";
import { useAutomationStore } from "../../stores/use-automation-store";

export default function WaitNodeFields() {
  const { duration, setDuration, unit, setUnit } = useAutomationStore();

  return (
    <>
      <div className="mt-4 lg:mt-8">
        <Badge color="zinc" className="h-8">
          <ClockIcon className="size-6" />
          <Heading>Wait</Heading>
        </Badge>
        <Divider className="mt-6" />
      </div>

      <div className="mt-10">
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Duration</Label>
              <Input
                name="duration"
                type="number"
                min="1"
                placeholder="1"
                value={duration}
                onChange={(e) =>
                  setDuration(
                    parseInt(
                      !e.target.value || e.target.value < 1
                        ? 1
                        : e.target.value,
                      10
                    )
                  )
                }
              />
            </Field>

            <Field>
              <Label>Unit</Label>
              <Listbox value={unit} onChange={setUnit}>
                <ListboxOption value="minute">Minute</ListboxOption>
                <ListboxOption value="hour">Hour</ListboxOption>
                <ListboxOption value="day">Day</ListboxOption>
              </Listbox>
            </Field>
          </FieldGroup>
        </Fieldset>
      </div>
    </>
  );
}
