import { createInertiaApp } from "@inertiajs/react";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import Application from "../layouts/application";
import WithSidebar from "../layouts/with-sidebar";

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  title: (title) => (title ? `${title} - Lavish` : "Lavish"),

  // Set progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  progress: {
    color: "#8b5cf6",
  },

  resolve: (name) => {
    const pages = import.meta.glob("../pages/**/*.jsx", { eager: true });
    // return pages[`../pages/${name}.jsx`];

    // To use a default layout, import the Layout component
    // and use the following lines.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts
    //
    const page = pages[`../pages/${name}.jsx`];
    page.default.layout ||= (page) =>
      page.props.current_user &&
      !/^\/automations\/[0-9a-zA-Z]{26}$/.test(page.props.path) ? (
        <WithSidebar children={page} {...page.props} />
      ) : (
        <Application children={page} {...page.props} />
      );
    return page;
  },

  setup({ el, App, props }) {
    const root = createRoot(el);

    root.render(createElement(App, props));
  },
});
