import { BoltIcon } from "@heroicons/react/24/solid";
import { Badge } from "../../components/badge";
import { Divider } from "../../components/divider";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../components/fieldset";
import { Heading } from "../../components/heading";
import { Listbox, ListboxOption } from "../../components/listbox";

export default function TriggerFields() {
  return (
    <>
      <div className="mt-4 lg:mt-8">
        <Badge color="yellow" className="h-8">
          <BoltIcon className="size-6" />
          <Heading>Trigger</Heading>
        </Badge>
        <Divider className="mt-6" />
      </div>

      <div className="mt-10">
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Type</Label>
              <Listbox value="contact_added" disabled>
                <ListboxOption value="contact_added">
                  Contact added
                </ListboxOption>
              </Listbox>
              <Description>More trigger types coming soon</Description>
            </Field>
          </FieldGroup>
        </Fieldset>
      </div>
    </>
  );
}
