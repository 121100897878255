import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "@xyflow/react";
import { useAutomationStore } from "../../stores/use-automation-store";
import AddNodeDropdown from "./add-node-dropdown";

export default function Edge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  source,
  target,
}) {
  const { active, addNode } = useAutomationStore();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan absolute origin-center pointer-events-auto"
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
          }}
        >
          {!active && (
            <AddNodeDropdown
              onEdge={true}
              onAddNode={(type) => addNode(type, source, target)}
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
