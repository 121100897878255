import {
  BoltIcon,
  ClockIcon,
  PaperAirplaneIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import { Handle, Position } from "@xyflow/react";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import { cn } from "../../lib/utils";
import { useAutomationStore } from "../../stores/use-automation-store";
import AddNodeDropdown from "./add-node-dropdown";

export default function Node({ data, selected }) {
  const { active, addNode, deleteNode } = useAutomationStore();

  return (
    <>
      {data.isAddNode ? (
        <>
          <Handle type="target" position={Position.Top} className="invisible" />
          <AddNodeDropdown onAddNode={addNode} />
          <Handle
            type="source"
            position={Position.Bottom}
            className="invisible"
          />
        </>
      ) : (
        <div
          className={cn(
            "relative group/outer w-60 text-center rounded-lg bg-white px-6 py-4",
            "dark:bg-zinc-900 dark:hover:ring-white/20",
            selected
              ? "ring-2 ring-violet-500 hover:ring-violet-500 shadow-md"
              : "ring-1 ring-zinc-950/5 dark:ring-white/10 hover:ring-zinc-950/15 shadow-sm"
          )}
        >
          <Handle type="target" position={Position.Top} className="invisible" />

          {!active && !data.trigger_type && (
            <div className="hidden group-hover/outer:block absolute right-2 top-2.5">
              <Button
                plain
                className="hover:!bg-pink-100 group/inner cursor-pointer"
                onClick={() => deleteNode(data.id)}
              >
                <TrashIcon className="group-hover/inner:text-pink-400" />
              </Button>
            </div>
          )}

          <div>
            {data.trigger_type ? (
              <Badge color="yellow">
                <BoltIcon className="size-4" />
                Trigger
              </Badge>
            ) : data.node_type === "wait" ? (
              <Badge color="zinc">
                <ClockIcon className="size-4" />
                Wait
              </Badge>
            ) : data.node_type === "email" ? (
              <Badge color="violet">
                <PaperAirplaneIcon className="size-4" />
                Email
              </Badge>
            ) : null}
          </div>

          <div className="text-sm font-medium mt-1.5 truncate">
            {data.trigger_type === "contact_added"
              ? "Contact added"
              : data.node_type === "wait"
              ? `Wait for ${data.settings.duration} ${data.settings.unit}${
                  data.settings.duration > 1 ? "s" : ""
                }`
              : data.node_type === "email"
              ? "Send email"
              : null}
          </div>

          <Handle
            type="source"
            position={Position.Bottom}
            className="invisible"
          />
        </div>
      )}
    </>
  );
}
