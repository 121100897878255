import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";
import { NodeViewWrapper } from "@tiptap/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { cn } from "../../lib/utils";

export function DynamicField({ editor, node, getPos }) {
  const [fallbackText, setFallbackText] = useState(node.attrs.fallback || "");
  const value = editor.isEditable
    ? `{{${node.attrs.label}}}`
    : node.attrs.value || `{{${node.attrs.label}}}`;

  function handleUpdateFallback(event) {
    event.preventDefault();

    editor.view.dispatch(
      editor.view.state.tr.setNodeMarkup(getPos(), null, {
        ...node.attrs,
        fallback: fallbackText === "" ? null : fallbackText,
      })
    );

    requestAnimationFrame(() => {
      editor.commands.focus();
    });
  }

  function handleDelete() {
    editor.view.dispatch(
      editor.state.tr.delete(getPos(), getPos() + node.nodeSize)
    );

    requestAnimationFrame(() => {
      editor.commands.focus();
    });
  }

  return (
    <NodeViewWrapper
      as="span"
      className={cn(
        "inline-flex",
        editor.isEditable &&
          "items-center gap-x-1.5 rounded-md px-1 font-medium forced-colors:outline bg-cyan-400/20 text-cyan-700 hover:bg-cyan-400/30 dark:bg-cyan-400/10 dark:text-cyan-300 dark:hover:bg-cyan-400/15"
      )}
    >
      <Popover
        onOpenChange={(open) =>
          !open && setFallbackText(node.attrs.fallback || "")
        }
      >
        <PopoverTrigger asChild>
          <button
            className="flex items-center gap-x-1"
            disabled={!editor.isEditable}
          >
            {value}
            {editor.isEditable && <PencilSquareIcon className="size-4" />}
          </button>
        </PopoverTrigger>

        <PopoverContent
          side="top"
          className={cn(
            // Base styles
            "isolate w-max rounded-xl p-1 bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75",
            "shadow-lg border-none ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10",
            // Animations
            "data-[state=open]:!animate-none data-[state=closed]:!zoom-out-100 data-[state=closed]:!duration-100 data-[state=closed]:ease-in"
          )}
        >
          <form
            className="flex gap-x-1"
            onSubmit={(event) => handleUpdateFallback(event)}
          >
            <Input
              aria-label="Fallback text"
              name="fallback"
              placeholder="Fallback when the field is empty"
              value={fallbackText}
              onChange={(e) => setFallbackText(e.target.value)}
            />

            <Button type="submit">Apply</Button>

            <Button
              plain
              className="hover:!bg-pink-100 group"
              onClick={handleDelete}
            >
              <TrashIcon className="group-hover:text-pink-600" />
            </Button>
          </form>
        </PopoverContent>
      </Popover>
    </NodeViewWrapper>
  );
}
