import { showSuccessToastWithConfetti } from "@/lib/utils";
import { applyEdgeChanges, applyNodeChanges } from "@xyflow/react";
import axios from "axios";
import debounce from "lodash/debounce";
import { create } from "zustand";

const gapFromTop = 40;
const gapBetweenNodes = 88;
const nodeHeight = 84;
const addNodeHeight = 24;

export const useAutomationStore = create((set, get) => ({
  // Automation details
  id: null,
  name: "",
  active: false,
  initialNodes: [],
  selectedNode: null,
  containerWidth: 0,

  // Node states
  nodes: [],
  edges: [],

  // Wait node state
  duration: 1,
  unit: "day",

  // Email node state
  subject: "",
  previewText: "",
  senderId: "",
  defaultSenderId: "",
  senders: [],
  jsonContent: "{}",

  initialize: (automation, defaultNodes, defaultSenderId, senders) => {
    set({
      id: automation.id,
      name: automation.name,
      active: automation.active,
      initialNodes: defaultNodes,
      selectedNode: null,
      defaultSenderId,
      senders,
    });
    get().updateFlowElements();
  },
  setName: (name) => {
    set({ name });
    saveAutomationState(get());
  },
  setActive: (active) => {
    set({ active });
    saveAutomationState(get());

    const { containerWidth } = get();
    get().updateFlowElements(containerWidth);

    if (active) {
      setTimeout(() => {
        showSuccessToastWithConfetti("Your automation is now active.");
      }, 1000);
    }
  },
  setSelectedNode: (node) => {
    set({ selectedNode: node });

    if (node) {
      if (node.node_type === "wait") {
        set({
          duration: node.settings?.duration || 1,
          unit: node.settings?.unit || "day",
        });
      } else if (node.node_type === "email") {
        set({
          subject: node.settings?.subject || "",
          previewText: node.settings?.preview_text || "",
          senderId: node.settings?.sender_id || "",
          jsonContent: node.settings?.json_content || "{}",
        });
      }
    }
  },
  onNodesChange: (changes) => {
    set((state) => ({
      nodes: applyNodeChanges(changes, state.nodes),
    }));
  },
  onEdgesChange: (changes) => {
    set((state) => ({
      edges: applyEdgeChanges(changes, state.edges),
    }));
  },
  onNodeClick: (event, node) => {
    event.preventDefault();
    if (node.id === "add-node") return;
    if (get().active) return;
    get().setSelectedNode(get().initialNodes.find((n) => n.id === node.id));
  },
  onPaneClick: () => {
    get().setSelectedNode(null);
  },
  addNode: (nodeType, sourceId) => {
    const newNode = {
      id: `node-${Date.now()}`,
      node_type: nodeType,
      settings:
        nodeType === "wait"
          ? { duration: 1, unit: "day" }
          : nodeType === "email"
          ? {
              subject: "Untitled",
              preview_text: "",
              sender_id: get().defaultSenderId,
              json_content: "{}",
            }
          : {},
    };

    set((state) => {
      let updatedNodes;
      if (!sourceId) {
        updatedNodes = [...state.initialNodes, newNode];
      } else {
        const sourceIndex = state.initialNodes.findIndex(
          (node) => node.id === sourceId
        );
        if (sourceIndex === -1) {
          updatedNodes = [...state.initialNodes, newNode];
        } else {
          updatedNodes = [...state.initialNodes];
          updatedNodes.splice(sourceIndex + 1, 0, newNode);
        }
      }

      return { initialNodes: updatedNodes };
    });

    const { containerWidth } = get();
    get().updateFlowElements(containerWidth);

    get().setSelectedNode(null);
    saveAutomationState(get());
  },
  deleteNode: (nodeId) => {
    set((state) => ({
      initialNodes: state.initialNodes.filter((node) => node.id !== nodeId),
      selectedNode:
        state.selectedNode?.id === nodeId ? null : state.selectedNode,
    }));
    const { containerWidth } = get();
    get().updateFlowElements(containerWidth);
    saveAutomationState(get());
  },
  updateFlowElements: (width = 0) => {
    set((state) => ({
      containerWidth: width,
      nodes: transformNodes(
        state.initialNodes,
        width,
        state.active,
        state.selectedNode
      ),
      edges: transformEdges(state.initialNodes, state.active),
    }));
  },
  updateNodeSettings: ({ skipRerender = false }) => {
    const {
      selectedNode,
      duration,
      unit,
      subject,
      previewText,
      senderId,
      jsonContent,
    } = get();

    if (!selectedNode) return;

    set((state) => ({
      initialNodes: state.initialNodes.map((node) => {
        if (node.id === selectedNode.id) {
          if (node.node_type === "wait") {
            return { ...node, settings: { duration, unit } };
          } else if (node.node_type === "email") {
            return {
              ...node,
              settings: {
                subject,
                preview_text: previewText,
                sender_id: senderId,
                json_content: jsonContent,
              },
            };
          }
        }
        return node;
      }),
    }));

    if (!skipRerender) {
      const { containerWidth } = get();
      get().updateFlowElements(containerWidth);
    }

    saveAutomationState(get());
  },
  setDuration: (duration) => {
    set({ duration });
    get().updateNodeSettings({});
  },
  setUnit: (unit) => {
    set({ unit });
    get().updateNodeSettings({});
  },
  setSubject: (subject) => {
    set({ subject });
    get().updateNodeSettings({ skipRerender: true });
  },
  setPreviewText: (previewText) => {
    set({ previewText });
    get().updateNodeSettings({ skipRerender: true });
  },
  setSenderId: (senderId) => {
    set({ senderId });
    get().updateNodeSettings({ skipRerender: true });
  },
  setJsonContent: (jsonContent) => {
    set({ jsonContent });
    get().updateNodeSettings({ skipRerender: true });
  },
}));

const transformNodes = (nodes, containerWidth = 0, active, selectedNode) => {
  if (!nodes.length) return [];

  const transformed = nodes.map((node, index) => ({
    id: node.id,
    type: "node",
    position: {
      x: Math.max(0, (containerWidth - (node.data?.isAddNode ? 24 : 240)) / 2),
      y: index * (nodeHeight + gapBetweenNodes) + gapFromTop,
    },
    data: node,
    draggable: false,
    selectable: !active,
    selected: !active && node.id === selectedNode?.id,
  }));

  // Add the "+" node at the bottom if there are nodes
  if (nodes.length > 0 && !active) {
    transformed.push({
      id: "add-node",
      type: "node",
      position: {
        x: Math.max(0, (containerWidth - 24) / 2),
        y:
          transformed[transformed.length - 1].position.y +
          nodeHeight +
          gapBetweenNodes / 2 -
          addNodeHeight / 2,
      },
      data: { isAddNode: true },
      draggable: false,
    });
  }

  return transformed;
};

const transformEdges = (nodes, active) => {
  const edges = [];

  // Connect all nodes in sequence
  for (let i = 0; i < nodes.length - 1; i++) {
    edges.push({
      id: `edge-${nodes[i].id}-${nodes[i + 1].id}`,
      source: nodes[i].id,
      target: nodes[i + 1].id,
      style: { stroke: "#09090B1A", strokeWidth: 1.5 },
      type: "edge",
      selectable: false,
      animated: active,
    });
  }

  // Add edge to the "+" node
  if (nodes.length > 0 && !active) {
    edges.push({
      id: `edge-${nodes[nodes.length - 1].id}-add-node`,
      source: nodes[nodes.length - 1].id,
      target: "add-node",
      style: { stroke: "#09090B1A", strokeWidth: 1.5 },
      type: "smoothstep",
      selectable: false,
      animated: active,
    });
  }

  return edges;
};

const saveAutomationState = debounce(async (state) => {
  try {
    await axios.put(`/automations/${state.id}`, {
      automation: {
        name: state.name,
        active: state.active,
      },
      nodes: state.initialNodes
        .filter((node) => node.node_type)
        .map((node, index) => ({
          node_type: node.node_type,
          position: index,
          settings: node.settings,
        })),
    });
  } catch (error) {
    console.error("Failed to save automation:", error);
  }
}, 2000);
