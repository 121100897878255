import { BoltIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/button";
import { Subheading } from "../../components/heading";
import { Text } from "../../components/text";

export default function Empty() {
  return (
    <div className="text-center mt-40">
      <BoltIcon className="mx-auto size-16 text-zinc-400" />
      <Subheading className="mt-2">You have no automations yet</Subheading>
      <Text className="mt-1">Get started by creating a new automation.</Text>
      <div className="mt-6">
        <Button color="violet" href="/automations/new">
          Create automation
        </Button>
      </div>
    </div>
  );
}
