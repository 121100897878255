import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { Badge } from "../../components/badge";
import { Divider } from "../../components/divider";
import { Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Heading } from "../../components/heading";
import { Input } from "../../components/input";
import {
  Listbox,
  ListboxDescription,
  ListboxLabel,
  ListboxOption,
} from "../../components/listbox";
import { useAutomationStore } from "../../stores/use-automation-store";
import EditEmailDrawer from "./edit-email-drawer";

export default function EmailNodeFields() {
  const {
    subject,
    setSubject,
    previewText,
    setPreviewText,
    senderId,
    setSenderId,
    senders,
  } = useAutomationStore();

  return (
    <>
      <div className="mt-4 lg:mt-8">
        <Badge color="violet" className="h-8">
          <PaperAirplaneIcon className="size-6" />
          <Heading>Email</Heading>
        </Badge>
        <Divider className="mt-6" />
      </div>

      <div className="mt-10">
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Subject</Label>
              <Input
                name="subject"
                placeholder="Welcome to Lavish"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Field>

            <Field>
              <Label>Preview Text</Label>
              <Input
                name="previewText"
                placeholder="We're excited to have you onboard!"
                value={previewText}
                onChange={(e) => setPreviewText(e.target.value)}
              />
            </Field>

            <Field>
              <Label>Sender</Label>
              <Listbox value={senderId} onChange={setSenderId}>
                {senders.map((sender) => (
                  <ListboxOption key={sender.id} value={sender.id}>
                    <ListboxLabel>{sender.name}</ListboxLabel>
                    <ListboxDescription>{sender.email}</ListboxDescription>
                  </ListboxOption>
                ))}
              </Listbox>
            </Field>

            <Field className="text-right">
              <EditEmailDrawer />
            </Field>
          </FieldGroup>
        </Fieldset>
      </div>
    </>
  );
}
