import { cn } from "@/lib/utils";
import {
  DraggableDivider,
  DraggableHeading,
  DraggableImage,
  DraggableListItem,
  DraggableParagraph,
} from "@/tiptap/extensions/draggable";
import { DynamicField } from "@/tiptap/extensions/dynamic-field";
import { SlashCommand } from "@/tiptap/extensions/slash-command";
import LinkExtension from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

export const useEditableEditor = ({ content, id, onTransaction, onUpdate }) => {
  return useEditor({
    content: !!content && JSON.parse(content),
    editorProps: {
      attributes: {
        id,
        class: cn(
          "prose prose-base prose-zinc mx-auto max-w-[708px] caret-zinc-900 focus:outline-none"
        ),
      },
      handleDOMEvents: {
        keydown: (_view, event) => {
          if (["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
            const slashCommand = document.querySelector("#slash-command");

            if (slashCommand) {
              return true;
            }
          }
        },
      },
    },
    extensions: [
      StarterKit.configure({
        blockquote: false,
        code: false,
        codeBlock: false,
        dropcursor: {
          width: 4,
          color: "#93c5fd",
        },
        heading: false,
        paragraph: false,
        horizontalRule: false,
        listItem: false,
      }),
      Typography,
      LinkExtension.extend({ inclusive: false }).configure({
        HTMLAttributes: {
          class: "hover:cursor-pointer",
        },
      }),
      Underline,
      SlashCommand,
      DraggableHeading.configure({
        levels: [1, 2, 3],
      }),
      DraggableParagraph,
      DraggableDivider,
      DraggableListItem,
      DraggableImage,
      DynamicField,
    ],
    onTransaction,
    onUpdate,
  });
};
