import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head } from "@inertiajs/react";
import { ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useEffect, useRef } from "react";
import { Link } from "../../components/link";
import { useAutomationStore } from "../../stores/use-automation-store";
import AutomationFields from "./automation-fields";
import Edge from "./edge";
import EmailNodeFields from "./email-node-fields";
import Node from "./node";
import TriggerFields from "./trigger-fields";
import WaitNodeFields from "./wait-node-fields";

const nodeTypes = { node: Node };
const edgeTypes = { edge: Edge };

export default function Show({
  automation,
  automation_nodes,
  default_sender_id,
  senders,
}) {
  const reactFlowWrapper = useRef(null);
  const {
    // State
    name,
    selectedNode,
    nodes,
    edges,

    // Actions
    initialize,
    onNodesChange,
    onEdgesChange,
    onNodeClick,
    onPaneClick,
    updateFlowElements,
  } = useAutomationStore();

  // Initialize automation data
  useEffect(() => {
    initialize(automation, automation_nodes, default_sender_id, senders);
  }, [automation, automation_nodes, initialize]);

  // Update flow elements when container size changes
  useEffect(() => {
    const updateNodesPosition = () => {
      if (reactFlowWrapper.current) {
        const { width } = reactFlowWrapper.current.getBoundingClientRect();
        updateFlowElements(width);
      }
    };

    updateNodesPosition();
    window.addEventListener("resize", updateNodesPosition);
    return () => window.removeEventListener("resize", updateNodesPosition);
  }, [updateFlowElements]);

  return (
    <>
      <Head title={name} />

      <div className="flex h-screen">
        <div className="p-2">
          <div className="h-full w-96 p-6 lg:rounded-lg lg:bg-white lg:p-10 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
            <div>
              <Link
                href={`/automations`}
                className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400"
              >
                <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                <span className="max-w-48 line-clamp-1">Automations</span>
              </Link>
            </div>

            {!selectedNode ? (
              <AutomationFields />
            ) : selectedNode.trigger_type ? (
              <TriggerFields />
            ) : selectedNode.node_type === "wait" ? (
              <WaitNodeFields />
            ) : selectedNode.node_type === "email" ? (
              <EmailNodeFields key={selectedNode.id} />
            ) : null}
          </div>
        </div>

        <div className="relative m-2 grow">
          <ReactFlow
            ref={reactFlowWrapper}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            panActivationKeyCode={null}
            panOnDrag={false}
            panOnScroll={false}
            zoomActivationKeyCode={null}
            zoomOnDoubleClick={false}
            zoomOnPinch={false}
            zoomOnScroll={false}
            proOptions={{ hideAttribution: true }}
            className="bg-zinc-50 dark:bg-zinc-950"
          />
        </div>
      </div>
    </>
  );
}
